import { APIFilter } from '@/utils/api'

export default {
  async selectSobre (Vue, idsobre) {
    const apiFilter = new APIFilter()
      .addExact('idsobre', idsobre)
    const resp = await Vue.$api.call('sobre.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idsobre) {
    let apiCalls = []
    const apiFilter = new APIFilter()
      .addExact('idsobre', idsobre)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectSobreEnvio', method: 'sobreEnvio.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectSobreDocumento', method: 'sobreDocumento.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectSobreDestinatario', method: 'sobreDestinatario.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push(
      { name: 'selectDestinatariosFaltan', method: 'sobreDestinatario.selectDestinatariosFaltan', params: { idsobre, wrapper: 'count' } }
    )
    return await Vue.$api.batchCall(apiCalls)
  },
}
